import dayjs from "dayjs";

const useTaskFilter = () => {
    const filterTask = (filters: any) => {
        // const params = new URLSearchParams([]);
        let params = ''
        filters.forEach((filter: any) => {
            if (filter.value !== '' && filter.value !== null) {
                switch (filter.type) {
                    case 'booklet':
                        const bookletArr = filter.value.map((booklet: any) => `&booklets[]=${encodeURIComponent(booklet.value)}`).join('')
                        params += bookletArr;
                        break;
                    case 'accurateSearch':
                        params += `&accurateSearch=${filter.value}`;
                        break;
                    case 'search':
                        params += `&label=${filter.value}`;
                        break;
                    case 'tag':
                        const tagsArr = filter.value.map((tag: any) => `&tags[]=${tag.value}`).join('')
                        params += tagsArr;
                        break;
                    case 'status':
                        if (filter.value === 'all') break
                        if (filter.value !== 'inProgress' && filter.value.substring(0, 2) === 'in') {
                            const days = filter.value.substring(2)
                            const date = dayjs().format('YYYY-MM-DD')

                            params += `&status=todo&date=${date}&nbDays=${days}`;
                            break
                        }
                        params += `&status=${filter.value}`;
                        break;
                }
            }
        });

        return '?' + params.substring(1);
    }

    return { filterTask };
}

export default useTaskFilter;
